<template>
    <div class="componentIndex">
        <el-row v-if="showEdit" style="padding: 25px">
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                    <el-row>
                        <el-form-item label="名称及编号">
                            <el-input style="width: 100px" placeholder=" " v-model="formInline.search"></el-input>
                        </el-form-item>
                        <el-form-item label="日期">
                            <el-date-picker
                                style="width: 210px"
                                v-model="formInline.time"
                                type="daterange"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="价格">
                            <el-input style="width: 100px" placeholder=" " v-model="formInline.price"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select
                                clearable
                                style="width: 100px"
                                v-model="formInline.status"
                                placeholder="全部状态"
                            >
                                <el-option
                                    v-for="item in constructStatus"
                                    :key="item.status"
                                    :label="item.name"
                                    :value="item.status"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="材质">
                            <el-input style="width: 100px" placeholder=" " v-model="formInline.material"></el-input>
                        </el-form-item>
                        <el-form-item label="品牌">
                            <el-select
                                clearable
                                collapse-tags
                                style="width: 100px"
                                v-model="formInline.brand_id"
                                placeholder="全部品牌"
                            >
                                <el-option
                                    v-for="item in brand_list"
                                    :key="item.b_id"
                                    :label="item.b_name"
                                    :value="item.b_id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="comIndex" label="体系">
                            <el-cascader
                                style="width: 180px"
                                v-model="formInline.system"
                                :popper-append-to-body="false"
                                :options="options"
                                :props="{
                                    multiple: true,
                                    checkStrictly: true,
                                    value: 'id',
                                    label: 'setup_name',
                                    children: 'material_list'
                                }"
                                clearable
                            />
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item>
                            <el-button type="primary" @click="generate">生成版本配置</el-button>
                            <!--                            <el-upload style="display: inline-block;margin: 0 10px"-->
                            <!--                                       action=""-->
                            <!--                                       :show-file-list="false"-->
                            <!--                                       accept=".txt,.TXT"-->
                            <!--                                       :auto-upload="false"-->
                            <!--                                       :on-change="uploadConfig">-->
                            <!--                                <el-button type="primary" size="small">上传配置</el-button>-->
                            <!--                            </el-upload>-->
                            <el-button type="primary" @click="init">查询</el-button>
                            <el-button type="primary" @click="down">导出</el-button>
                            <el-button :disabled="!permissionsAction.add" type="primary" @click="edit()"
                                >新建</el-button
                            >
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>

            <div style="width: 100%">
                <el-table
                    :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }"
                    :data="tableData"
                    border
                    style="width: 100%"
                >
                    <el-table-column width="60" label="序号" type="index" :index="indexMethod"> </el-table-column>
                    <el-table-column prop="number" label="编号"></el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="b_name" label="品牌"></el-table-column>
                    <el-table-column prop="setup_name" label="所属类"></el-table-column>
                    <el-table-column prop="material" label="材质"></el-table-column>
                    <el-table-column prop="price" label="价格">
                        <template #default="scope">
                            <div>￥{{ scope.row.price }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" prop="default_size" label="商品尺寸"></el-table-column>
                    <el-table-column prop="use_number" label="使用数">
                        <template #default="scope">
                            <div>{{ scope.row.use_number }}次</div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template #default="scope">
                            <el-button
                                :disabled="!permissionsAction.edit"
                                @click="edit(scope.row)"
                                type="text"
                                size="small"
                                >编辑</el-button
                            >
                            <el-button
                                v-if="parseInt(scope.row.status) == 1"
                                :disabled="!permissionsAction.enable"
                                @click="changeuse(scope.row, 1)"
                                type="text"
                                size="small"
                                >停用</el-button
                            >
                            <el-button
                                v-if="parseInt(scope.row.status) == 0"
                                :disabled="!permissionsAction.enable"
                                @click="changeuse(scope.row, 3)"
                                type="text"
                                size="small"
                                >启用</el-button
                            >
                            <el-button
                                :disabled="!permissionsAction.del"
                                @click="changeuse(scope.row, 2)"
                                type="text"
                                size="small"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.page"
                        :page-sizes="[10, 15, 30, 35]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.totalItems"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <MemberComponent v-else :compontId="compontId" @handleClose="handleClose"></MemberComponent>
    </div>
</template>
<script>
import MemberComponent from '../../components/MemberComponent.vue';
import { getList, changeUse, download, detail, generate, uploadConfig } from '../../common/api/member/index';

export default {
    name: 'member',
    components: { MemberComponent },
    data() {
        return {
            options: [],
            brand_list: [],
            constructStatus: [],
            compontId: '',
            showEdit: true,
            tableData: [],
            page: {
                page: 1,
                pageSize: 10,
                totalItems: 0
            },
            formInline: {
                search: '',
                status: '',
                price: '',
                time: '',
                material: '',
                brand_id: '',
                system: []
            },
            // 权限操作集合
            permissionsAction: {}
        };
    },
    created() {
        this.$nextTick(() => {
            this.init();
        });
        // this.$store.mutations.ClearKeep(this.$store.state,'member')
    },
    methods: {
        changeuse(row, type) {
            let title = '';
            if (type == 1) {
                title = '确认停用？';
            } else if (type == 2) {
                title = '确认删除？';
            } else {
                title = '确认启用？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                changeUse({ id: row.id, type: type }).then(res => {
                    this.$message.success(res.msg);
                    this.init();
                });
            });
        },
        down() {
            let _this = this;
            this.$confirm('确认导出?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let a = '';
                let b = [];
                if (this.formInline.system.length) {
                    this.formInline.system.forEach(ele => {
                        if (ele.length > 1) {
                            b.push(ele[1]);
                        } else {
                            a = ele[0];
                        }
                    });
                }
                let dt = {
                    search: this.formInline.search,
                    status: this.formInline.status,
                    price: this.formInline.price,
                    start_time: this.formInline.time ? this.formInline.time[0] : '',
                    end_time: this.formInline.time ? this.formInline.time[1] : '',
                    material: this.formInline.material,
                    brand_id: this.formInline.brand_id,
                    setup_id: a,
                    material_ids: b
                };
                download(dt).then(res => {
                    _this.$message.success('导出成功');
                });
            });
        },
        edit(row) {
            if (row) {
                this.compontId = row.id;
                this.showEdit = false;
            } else {
                this.$router.push('/memberUpload');
            }
        },
        formatDate(value) {
            if (value) {
                let date = new Date(parseInt(value * 1000));
                let Y = date.getFullYear() + '-';
                let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
            }
        },
        handleClose() {
            this.showEdit = true;
            this.init();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        uploadConfig(file) {
            let _this = this;
            if (_this.formInline.brand_id == '') {
                _this.$message.error('请先选择品牌');
                return false;
            }
            let dt = new FormData();
            dt.append('brand_id', _this.formInline.brand_id);
            dt.append('deploy_file', file.raw);
            uploadConfig(dt).then(res => {
                _this.$message.success(res.msg);
            });
        },
        generate() {
            let _this = this;
            if (_this.formInline.brand_id == '') {
                _this.$message.error('请先选择品牌');
                return false;
            }
            this.$confirm('确认生成版本配置?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let a = '';
                let b = [];
                if (this.formInline.system.length) {
                    this.formInline.system.forEach(ele => {
                        if (ele.length > 1) {
                            b.push(ele[1]);
                        } else {
                            a = ele[0];
                        }
                    });
                }
                let dt = {
                    brand_id: this.formInline.brand_id,
                    setup_id: a,
                    material_ids: b
                };
                generate(dt).then(res => {
                    _this.$message.success('生成版本配置成功');
                });
            });
        },
        init() {
            let a = '';
            let b = [];
            if (this.formInline.system.length) {
                this.formInline.system.forEach(ele => {
                    if (ele.length > 1) {
                        b.push(ele[1]);
                    } else {
                        a = ele[0];
                    }
                });
            }
            let dt = {
                page: this.page.page,
                limit: this.page.pageSize,
                search: this.formInline.search,
                status: this.formInline.status,
                price: this.formInline.price,
                start_time: this.formInline.time ? this.formInline.time[0] : '',
                end_time: this.formInline.time ? this.formInline.time[1] : '',
                material: this.formInline.material,
                brand_id: this.formInline.brand_id,
                setup_id: a,
                material_ids: b
            };
            getList(dt).then(res => {
                this.brand_list = res.result.brand_list;
                this.constructStatus = res.result.constructStatus;
                this.tableData = res.result.construct_list.data;
                let { Add: add, Delete: del, Edit: edit, Enable: enable } = res.result.Power;
                this.permissionsAction = {
                    add,
                    del,
                    edit,
                    enable
                };
                this.page.totalItems = res.result.construct_list.total;
                this.options = res.result.setup;
            });
        }
    }
};
</script>
<style>
.componentIndex .el-input-group__append {
    background-color: #409eff;
    color: #ffffff;
    border: 1px solid #409eff;
}
.componentIndex .comIndex .el-cascader__dropdown {
    z-index: 376456654564 !important;
}
</style>