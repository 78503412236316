import $http from '../../../utils/http.js';

/**
 * 特性管理列表
 * @param data
 */
export function getList(data) {
    return $http.get('/construct_list', data,'loadingDiv');
}

/**
 * 启用禁用
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function changeUse(data) {
    return $http.post('/deactivate_construct', data,'loadingDiv');
}

/**
 * 下载
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function download(data) {
    return $http.exports('/export_list',data,'loadingDiv','构件.xlsx');
}

export function generate(data) {
    return $http.exportsTxt('/generate_deploy',data,'loadingDiv','版本配置.txt');
}


export function uploadConfig(data) {
    return $http.post('/upload_deploy', data,'loadingDiv','multipart/form-data');
}

/**
 * 上传编辑构件
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
// export function edit(data) {
//     if (data.id){
//         return $http.post('/change_construct', data,'loadingDiv','multipart/form-data');
//     }else{
//         return $http.post('/add_construct', data,'loadingDiv','multipart/form-data');
//     }
// }